import React, { useEffect, useState } from "react";
import Input from "../Input";
import cx from "classnames";


export type TimeInputProps = {
  value?: Date;
  onChange?: (value: Date | undefined) => void;
  onBlur?: (value: Date | undefined) => void;
  name?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean | React.ReactNode;
};

export function TimeInput({
  className,
  value,
  onChange,
  onBlur,
  required,
  ...props
}: TimeInputProps) {
  const [inputValue, setInputValue] = useState<string>(formatTimeValue(value));

  useEffect(() => {
    setInputValue(formatTimeValue(value));
  }, [value]);

  const parseAndUpdateTime = (time: string, callback?: (value: Date | undefined) => void) => {
    if (isValidTime(time)) {
      const [hours, minutes] = time.split(":").map(Number);
      const updatedDate = value ? new Date(value) : new Date();
      updatedDate.setHours(hours, minutes, 0, 0);
      callback?.(!isNaN(updatedDate.getTime()) ? updatedDate : undefined);
    } else if (!time) {
      callback?.(undefined);
    }
  };

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
    parseAndUpdateTime(newInputValue, onChange);
  };

  const handleInputBlur = () => {
    parseAndUpdateTime(inputValue, onBlur);
  };

  return (
    <Input
      className={cx("custom-time-input", "custom-date-or-time-input", className, {
        "custom-time-input--placeholder": !inputValue,
        "custom-time-input--optional": !required && !inputValue,
      })}
      type="time"
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      {...props}
    />
  );
}

function formatTimeValue(value: Date | undefined): string {
  return value instanceof Date ? formatTime(value) : "";
}

function formatTime(date: Date): string {
  return date.toTimeString().slice(0, 5);
}

function isValidTime(input: string): boolean {
  return input.length === 5 && input.includes(":");
}