import React, { useEffect, useState } from "react";
import Input from "../Input";
import cx from "classnames";

export type DateInputProps = {
  value?: Date | undefined;
  onChange?: (value: Date | undefined) => void;
  onBlur?: (value: Date | undefined) => void;
  name?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean | React.ReactNode;
  min?: Date | string;
  max?: Date | string;
};

export function DateInput({
  className,
  value,
  onChange,
  onBlur,
  min,
  max,
  required,
  ...props
}: DateInputProps) {
  const [inputValue, setInputValue] = useState<string>(formatToISO(value));

  useEffect(() => {
    value && setInputValue(formatToISO(value));
  }, [value]);

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);

    onChange && onChange(parseStringToDate(newInputValue));
  };

  const handleInputBlur = () => {
    if (onBlur) {
      const parsedDate = parseStringToDate(inputValue);
      onBlur(parsedDate);
    }
  };

  return (
    <Input
      className={cx("custom-date-input", "custom-date-or-time-input", className, {
        "custom-date-input--placeholder": !inputValue,
        "custom-date-input--optional": !required && !inputValue,
      })}
      type="date"
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      min={formatToISO(min)}
      max={formatToISO(max) || "2099-12-31"}
      {...props}
    />
  );
}

const formatToISO = (value?: Date | string): string =>
  value instanceof Date ? value.toISOString().split("T")[0] : value || "";

const parseStringToDate = (input: string): Date | undefined => {
  return input ? new Date(input) : undefined;
};