export function installTouchScrolling() {
  // próg przesunięcia od którego zaczynamy scrollowanie, nie jest stały bo gęstości px ekranu mogą się znacznie
  // różnić, więc podczas dotknięcia obliczamy z szerokości okna
  let sensitivity = 0;
  
  type VelocityRecord = [x: number, y: number, ts: number];
  
  const { abs, min, max, exp } = Math;
  // czy scrollujemy wzdłuż danej osi, czy jeszcze nie, bo skala ruchu nie przekroczyła progu?
  let movingX = false, movingY = false;
  // jeśli scrollujemy wzdłuż danej osi, to tutaj trzymamy poprzednią pozycję palca
  let lastY = 0, lastX = 0;
  // tutaj trzymamy poprzednią pozycję palca na potrzeby kalkulacji prędkości
  let lastVX = 0, lastVY = 0;
  // zakumulowane zmiany pozycji wzdłuż osi, ustawiamy to w ramach touchmove, a aplikujemy do scrollTop/scrollLeft w requestAnimationFrame
  let accX = 0, accY = 0;
  
  // id palca... żeby zidentyfikować palec zaakceptowany w touchstart potem w touchmove... ale to nie jest specjalnie dobrze zrobione w przeglądarkach...
  let touchId = 0;
  // timestampy dotknięcia i ostatniego ruchu palca
  let touchedAt = 0, lastMovedAt = 0;
  
  // do obliczania ruchomej średniej prędkości
  let velocities: VelocityRecord[], velocitiesIdx = 0;
  
  // stosy elementów, które możemy scrollować wzdłuż osi, w kolejności od najbardziej zagnieżdżonego
  let vStack: Element[], hStack: Element[];
  // ostatnio scrollowane elementy
  let $vscroll: Element | null, $hscroll: Element | null;
  
  // tokeny requestAnimationFrame
  let kineticScrollToken: any = null;
  let deferredScrollToken: any = null;
  
  const scrollableOverflows = new Set(["auto", "scroll"]);
  const isScrollableVertically = ($el: Element) => $el.tagName !== "TD" && $el.tagName !== "TR" && $el.scrollHeight > $el.clientHeight && scrollableOverflows.has(getComputedStyle($el).overflowY);
  const isScrollableHorizontally = ($el: Element) => $el.tagName !== "TD" && $el.tagName !== "TR" && $el.scrollWidth > $el.clientWidth && scrollableOverflows.has(getComputedStyle($el).overflowX);
  
  function findScrollableAncestors($initial: Element) {
    const vs: Element[] = [], hs: Element[] = [];
    let $el: Element | null = $initial;
    while ($el) {
      if (isScrollableVertically($el)) vs.push($el);
      if (isScrollableHorizontally($el)) hs.push($el);
      $el = $el.parentElement;
    }
    return [hs, vs];
  }
  
  function scrollStack(
    stack: readonly Element[],
    by: number,
    clientHeight: "clientHeight" | "clientWidth",
    scrollHeight: "scrollHeight" | "scrollWidth",
    scrollTop: "scrollTop" | "scrollLeft",
    top: "top" | "left"
  ) {
    let $last = null;
    for (let i = 0; i < stack.length && abs(by) >= 1; i++) {
      const $el = stack[i];
      if (by > 0) {
        const h = max(0, $el[scrollHeight] - $el[scrollTop] - $el[clientHeight]);
        // console.log("sV+", h, min(h, by), $el);
        if (h > 0) {
          const x = min(h, by);
          by -= x;
          $el.scrollBy({ [top]: x, behavior: "instant" }); // ważne, bo element może mieć scroll-behavior: smooth
          $last = $el;
        }
      }
      else if (by < 0) {
        const h = $el[scrollTop];
        // console.log("sV-", h, min(h, -by), $el);
        if (h > 0) {
          const x = min(h, -by);
          by += x;
          $el.scrollBy({ [top]: -x, behavior: "instant" });
          $last = $el;
        }
      }
    }
    return $last;
  }
  
  document.addEventListener("touchstart", function scrollingOnTouchStart(e) {
    if (e.touches.length !== 1 || e.defaultPrevented) {
      touchId = 0;
      return;
    }
    
    touchId = 1 + e.touches[0].identifier;
    const { pageX, pageY } = e.touches[0];
    
    [hStack, vStack] = findScrollableAncestors(e.target as Element);
    
    // Record touch start positions
    lastVY = lastY = pageY;
    lastVX = lastX = pageX;
    accX = 0; accY = 0;
    movingX = movingY = false;
    velocities = [];
    velocitiesIdx = 0;
    touchedAt = performance.now();
    sensitivity = window.innerWidth / 10;
    
    cancelAnimationFrame(kineticScrollToken);
  });
  
  document.addEventListener("touchmove", function scrollingOnTouchMove(e) {
    if (e.defaultPrevented || !touchId) {
      // defaultPrevented tutaj np. nam react-beautiful-dnd ustawi
      touchId = 0;
      return;
    }
    
    let touch: Touch | null = null;
    for (let i = 0; i < e.touches.length; i++) {
      if (e.touches[i].identifier === touchId - 1) {
        touch = e.touches[i];
        break;
      }
    }
    
    if (!touch) {
      touchId = 0;
      return;
    }
    
    const { clientX: pageX, clientY: pageY } = touch;
    
    const deltaY = pageY - lastY;
    const deltaX = pageX - lastX;
    
    lastMovedAt = performance.now();
    
    if (!movingY && abs(deltaY) >= sensitivity * (1 + +movingX)) { movingY = true; }
    if (!movingX && abs(deltaX) >= sensitivity * (1 + +movingY)) { movingX = true; }
    
    let velX = 0;
    let velY = 0;
    
    if (movingX) {
      accX -= deltaX;
      velX = pageX - lastVX;
      lastX = pageX;
    }
    
    if (movingY) {
      accY -= deltaY;
      velY = pageY - lastVY;
      lastY = pageY;
    }
    
    if ((movingX || movingY) && !deferredScrollToken)
      deferredScrollToken = requestAnimationFrame(deferredScroll);
    
    lastVY = pageY;
    lastVX = pageX;
    
    if (velocities.length < 4)
      velocities.push([velX, velY, lastMovedAt]);
    else {
      const V = velocities[velocitiesIdx];
      V[0] = velX;
      V[1] = velY;
      V[2] = lastMovedAt;
    }
    velocitiesIdx = (velocitiesIdx + 1) & 3;
    
    e.preventDefault();
  }, { passive: false } /* w FF pasywny handler powoduje, że przy pierwszym dotknięciu przewijanie jest 2x za szybkie... */);
  
  function deferredScroll() {
    deferredScrollToken = null;
    
    if (abs(accX) > 1) {
      $hscroll = scrollStack(hStack, accX, "clientWidth", "scrollWidth", "scrollLeft", "left");
      accX = 0;
    }
    
    if (abs(accY) > 1) {
      $vscroll = scrollStack(vStack, accY, "clientHeight", "scrollHeight", "scrollTop", "top");
      accY = 0;
    }
  }
  
  function calculateVelocity() {
    if (velocities.length < 1)
      return [0, 0];
    
    let earliest = 1 << 52;
    let x = 0, y = 0;
    for (let i = 0; i < velocities.length; i++) {
      const v = velocities[i];
      x += v[0];
      y += v[1];
      if (v[2] < earliest)
        earliest = v[2];
    }
    const now = performance.now();
    const it = 1 / (now - earliest);
    x *= it; y *= it;
    return [x, y];
  }

  document.addEventListener("touchend", function scrollingOnTouchEnd() {
    if (touchId) {
      touchId = 0;
      startKineticScroll($hscroll, $vscroll);
    }
  });
  
  function startKineticScroll($h: Element | null, $v: Element | null) {
    let $scrollable: Element;
    let prop: "top" | "left";
    let velocity: number;
    
    const [velocityX, velocityY] = calculateVelocity();
    
    if (abs(velocityY) >= abs(velocityX) && $v) {
      $scrollable = $v;
      prop = "top";
      velocity = velocityY;
    }
    else if ($h) {
      $scrollable = $h;
      prop = "left";
      velocity = velocityX;
    }
    else {
      return;
    }
    
    const start = performance.now();
    let lastFrame = start;
    function kineticScroll() {
      const now = performance.now()
      const decelerationTime = now - start;
      const deceleration = exp(-0.003 * decelerationTime); // wykładnicze hamowanie
      const frameTime = now - lastFrame;
      lastFrame = now;
      
      const distance = velocity * deceleration * frameTime; // d = v * t
      
      if (abs(distance) < 1) return;
      
      $scrollable.scrollBy({ [prop]: -distance, behavior: "instant" });
      
      kineticScrollToken = requestAnimationFrame(kineticScroll);
    }
    
    kineticScrollToken = requestAnimationFrame(kineticScroll);
  }
}
