import "./utils/themes";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ScrollToTopWrapper from "components/common/ScrollToTopWrapper";
import App from "./components/App";

import store, { sagaMiddleware } from "./store";

import rootSaga from "./sagas";

import "./utils/errorReporting";
import bootstrap from "bootstrap";

import "./styles/index.scss";
import { installTouchScrolling } from "./utils/touchScrolling";

document.title = `${bootstrap.title2} • ${bootstrap.name}`;

sagaMiddleware.run(rootSaga);

const root = document.getElementById("root");

root && ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTopWrapper>
        <Route component={App} />
      </ScrollToTopWrapper>
    </Router>
  </Provider>,
  root
);

installTouchScrolling();