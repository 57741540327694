import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import moment from "moment";
import isEqualReact from "react-fast-compare";

import { useSignal } from "utils/hooks";
import { emptyArray } from "utils/constants";

// Zlokalizowane formaty (localized formats):
// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/

//
// Date
//

type DateProps = {
  at?: Date | moment.Moment | string | null | undefined,
  long?: boolean,
  dayOfTheWeek?: boolean,
  monthName?: boolean
};

// TODO: dodać wsparcie dla dzisiaj/wczoraj/jutro

export const DateOnly = React.memo(function DateOnly(props: DateProps) {
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  const { long, dayOfTheWeek = long, monthName = long } = props;
  const m = moment(props.at);
  const title = m.format("dddd, LL");
  
  let format;
  if (dayOfTheWeek)
    format = monthName ? "dddd, LL" : "dddd, L";
  else
    format = monthName ? "LL" : "L";
   
  const result = format === "dddd, LL" ? title : m.format(format);
  
  return <time title={title} dateTime={m.format("yyyy-MM-DD")}>{result}</time>;
}, isEqualReact);

//
// Time
//

type TimeProps = {
  at?: Date | moment.Moment | string | null | undefined,
  seconds?: boolean
};

export const Time = React.memo(function Time(props: TimeProps) {
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  return moment(props.at).format(props.seconds ? "LTS" : "LT") as any;
}, isEqualReact);

//
// DateTime
//

type DateTimeProps = {
  at?: Date | moment.Moment | string | null | undefined,
  dayOfTheWeek?: boolean,
  monthName?: boolean,
  long?: boolean,
  seconds?: boolean
};

export const DateTime = React.memo(function DateTime(props: DateTimeProps) {
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  const { long, dayOfTheWeek = long, monthName = long, seconds } = props;
  const m = moment(props.at);
  
  let format;
  if (dayOfTheWeek) {
    if (seconds)
      format = monthName ? "dddd, LL LTS" : "dddd, L LTS";
    else
      format = monthName ? "dddd, LL LT" : "dddd, L LT";
  }
  else {
    if (seconds)
      format = monthName ? "LL LTS" : "L LTS";
    else
      format = monthName ? "LL LT" : "L LT";
  }
  
  return <time title={m.format("LLLL")} dateTime={m.toISOString()}>{m.format(format)}</time>;
}, isEqualReact);

//
// TimeAgo
//

type TimeAgoProps = {
  at: Date | moment.Moment | string | null | undefined
};

export const TimeAgo = React.memo(function TimeAgo(props: TimeAgoProps) {
  type State = {
    onMount: () => () => void
  }
  
  const signal = useSignal();
  const stateRef = useRef(null as any as State);
  
  if (stateRef.current === null) {
    stateRef.current = {
      onMount: () => {
        registerForUpdates(signal);
        return () => {
          unregisterForUpdates(signal);
        }
      }
    }
  }
  
  // eslint-disable-next-line
  useEffect(stateRef.current.onMount, emptyArray);
  
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  const m = moment(props.at);
  
  return <time title={m.format("LLLL")} dateTime={m.toISOString()}>{m.fromNow()}</time>;
}, isEqualReact);

const SIGNALS = new Set<() => void>();
let timer: number | null = null;

function registerForUpdates(signal: () => void) {
  SIGNALS.add(signal);
  if (timer === null)
    timer = setInterval(ReactDOM.unstable_batchedUpdates, 30000, update) as any as number;
}

function unregisterForUpdates(signal: () => void) {
  SIGNALS.delete(signal);
  if (SIGNALS.size === 0 && timer !== null) {
    clearInterval(timer);
    timer = null;
  }
}

function update() {
  if (document.visibilityState !== "hidden") {
    for (let signal of SIGNALS) signal();
  }
}

export function atStartOfDay(): Date;
export function atStartOfDay(date: Date): Date;
export function atStartOfDay(date: undefined): undefined;
export function atStartOfDay(date?: Date) {
  if (arguments.length > 0 && date === undefined)
    return undefined;
  let d = date ? new Date(date) : new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

// FIXME: @marcin żadne API nie powinno polegać na tym, bo serwer używa innej precyzji niż klient, wszystko powinno być 1 <= x < 2
export function atEndOfDay(): Date;
export function atEndOfDay(date: Date): Date;
export function atEndOfDay(date: undefined): undefined;
export function atEndOfDay(date?: Date) {
  if (arguments.length > 0 && date === undefined)
    return undefined;
  let d = date ? new Date(date) : new Date();
  d.setHours(23, 59, 59, 999); // chcielibyśmy 999.999, ale się nie da...
  return d;
}

// UWAGA: a to niestety bardzo nie pomoże, bo w przeciwieńswie do atEndOfDay nie jest idempotentne
//        więc nie nadaje się do użycia w formularzach edycyjnych
export function atStartOfNextDay(): Date;
export function atStartOfNextDay(date: Date): Date;
export function atStartOfNextDay(date: undefined): undefined;
export function atStartOfNextDay(date?: Date) {
  if (arguments.length > 0 && date === undefined)
    return undefined;
  let d = new Date((date ? date.getTime() : Date.now()) + 86400000);
  d.setHours(0, 0, 0, 0);
  return d;
}
