import React, { useEffect, useState } from "react";
import Input from "../Input";
import cx from "classnames";

export type DateTimeInputProps = {
  value?: Date;
  onChange?: (value: Date | undefined) => void;
  onBlur?: (value: Date | undefined) => void;
  name?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean | React.ReactNode;
  minDate?: Date | string;
  maxDate?: Date | string;
  onFocus?: () => void;
};

export function DateTimeInput({
  className,
  value,
  onChange,
  onFocus,
  onBlur,
  minDate,
  maxDate,
  required,
  ...props
}: DateTimeInputProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [dateValue, setDateValue] = useState<string>(value ? getFormattedDate(value) : "");
  const [timeValue, setTimeValue] = useState<string>(value ? getFormattedTime(value) : "");

  useEffect(() => {
    if (value) {
      setDateValue(getFormattedDate(value));
      setTimeValue(getFormattedTime(value));
    }
  }, [value]);

  const updateDateTime = (date: string, time: string) => {
    onChange && onChange(getCombinedDateTime(date, time));
  };

  const handleDateChange = (date: string) => {
    setDateValue(date);
    updateDateTime(date, timeValue); 
  };

  const handleTimeChange = (time: string) => {
    setTimeValue(time);
    updateDateTime(dateValue, time);
  };

  const handleInputFocus = () => {
    onFocus && onFocus();
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    const isAnyFieldEmpty = dateValue.trim() === "" || timeValue.trim() === "";

    onBlur?.(isAnyFieldEmpty ? undefined : getCombinedDateTime(dateValue, timeValue));
  };

  return (
    <div className={cx("custom-dateTime-input", className)} lang="pl">
      <Input
        type="date"
        value={dateValue}
        onChange={handleDateChange}
        min={getFormattedDate(minDate)}
        max={getFormattedDate(maxDate) || "2099-12-31"}
        className={cx("custom-date-input", "custom-date-or-time-input", {
          "custom-date-input--placeholder": !dateValue,
          "custom-date-input--optional": !required && !dateValue,
          "custom-dateTime-input__date--focused": !required && isFocused && !dateValue,

        })}
        {...props}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <div className="custom-dateTime-input__time-wrapper">
        <Input
          type="time"
          value={timeValue}
          onChange={handleTimeChange}
          className={cx("custom-dateTime-input__time", "custom-date-or-time-input", {
            "custom-dateTime-input__time--placeholder": !timeValue,
            "custom-dateTime-input__time--optional": !required && !timeValue,
            "custom-dateTime-input__time--focused": !required && isFocused && !timeValue,
          })}
          required={required}
          {...props}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </div>
    </div>
  );
}

const getCombinedDateTime = (date: string, time: string): Date | undefined => {
  const isValid = isValidDate(date) && isValidTime(time);
  return isValid ? new Date(`${date}T${time}`) : undefined;
};

const formatDateToISO = (date: Date): string => {
  return date.toISOString().split("T")[0];
}

const getFormattedTime = (date: Date): string => {
  return date.toTimeString().split(" ")[0].slice(0, 5);
}

const getFormattedDate = (value: Date | string | undefined): string => {
  return value instanceof Date ? formatDateToISO(value) : value || "";
}

const isValidDate = (input: string): boolean => {
  return input.length === 10;
}

const isValidTime = (input: string): boolean => {
  return input.length === 5;
}