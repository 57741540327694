import { CapiClient, CapiCommand, capiDateFromString } from "./client";

import { CommonParamsDef, CommonParamsGet, CommonParamsSet } from "./common";

const checkValue = CapiCommand.checkValue;
const checkField = CapiCommand.checkField;
const parseDate = capiDateFromString;

export class SessionCreate extends CapiCommand {
  constructor(domain, user_id, maxRole) {
    super(["folksSessionCreate", [domain], { user_id, max_role: maxRole || "sysadmin" }]);

    checkValue(domain, "domain", "string");
    checkValue(user_id, "user_id", "string", "undefined");
    checkValue(maxRole, "max_role", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkField(data, "session_id", "string");
    checkField(data, "session_key", "string");
    checkField(data, "timeout", "number");
    checkField(data, "expires_at", "string");
    checkField(data, "role", "string", "undefined");

    return { ...data, expires_at: new Date(data.expires_at) };
  }
}

export class SessionDelete extends CapiCommand {
  constructor(session_id) {
    super(["folksSessionDelete", { session_id }]);

    checkValue(session_id, "session_id", "string", "undefined");
  }
}

function parseUserData(data, key) {
  let user = checkField(data, key, "object");

  checkField(user, "user_id", "string");
  checkField(user, "label", "string");

  return data;
}

export class SessionInfo extends CapiCommand {
  constructor(session_id) {
    super(["folksSessionInfo", { session_id }]);

    checkValue(session_id, "session_id", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");
    checkField(data, "session_id", "string");
    checkField(data, "domain", "string");
    checkField(data, "created_at", "string");
    checkField(data, "active_at", "string");
    checkField(data, "expires_at", "string");
    checkField(data, "role", "string", "undefined");

    if (data.user) data = parseUserData(data, "user");
    if (data.real_user) data = parseUserData(data, "real_user");

    return {
      ...data,
      created_at: parseDate(data.created_at),
      active_at: parseDate(data.active_at),
      expires_at: parseDate(data.expires_at)
    };
  }
}

export class SessionList extends CapiCommand {
  constructor(domain, login, user_id) {
    super(["folksSessionList", { domain, login, user_id }]);

    checkValue(domain, "domain", "string", "undefined");
    checkValue(login, "login", "string", "undefined");
    checkValue(user_id, "user_id", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "array");

    return data.map(session => {
      checkField(session, "session_id", "string");
      checkField(session, "login", "string", "undefined");
      checkField(session, "domain", "string");
      checkField(session, "created_at", "string");
      checkField(session, "active_at", "string");
      checkField(session, "expires_at", "string");
      checkField(session, "role", "string", "undefined");

      if (session.user) session = parseUserData(session, "user");
      if (session.real_user) session = parseUserData(session, "real_user");

      return {
        ...session,
        created_at: parseDate(session.created_at),
        active_at: parseDate(session.active_at),
        expires_at: parseDate(session.expires_at)
      };
    });
  }
}

export class SessionTokenUse extends CapiCommand {
  constructor(token) {
    super(["folksSessionTokenUse", [token]]);

    checkValue(token, "token", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");
    checkField(data, "session_id", "string");
    checkField(data, "session_key", "string");
    
    return data;
  }
}

export class PassportCreate extends CapiCommand {
  constructor() {
    super(["folksPassportCreate"]);
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkField(data, "passport_id", "string");
    checkField(data, "passport_key", "string");

    return { ...data, expires_at: new Date(data.expires_at) };
  }
}

export class UserCreate extends CapiCommand {
  constructor(login, label, obj_args = {}) {
    super(["folksUserCreate", [login, label], obj_args]);

    checkValue(login, "login", "string");
    checkValue(label, "label", "string");
    checkValue(obj_args, "obj_args", "object");

    checkField(obj_args, "addresses", "array", "undefined");
    checkField(obj_args, "role", "string", "undefined");
    checkField(obj_args, "user_id", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");
    checkField(data, "user_id", "string");

    return data;
  }
}

export class UserDelete extends CapiCommand {
  constructor(user_id) {
    super(["folksUserDelete", [user_id]]);

    checkValue(user_id, "user_id", "string");
  }
}

export class UserPassSet extends CapiCommand {
  constructor(user_id, password) {
    super(["folksUserPassSet", [user_id, password]]);

    checkValue(user_id, "user_id", "string");
    checkValue(password, "password", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");
    checkField(data, "expiry", "string");

    return { ...data, expiry: parseDate(data.expiry) };
  }
}

export class UserPassReset extends CapiCommand {
  constructor(user_id) {
    super(["folksUserPassReset", [user_id]]);

    checkValue(user_id, "user_id", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    return { ...data, expiry: parseDate(data.expiry) };
  }
}

export class UserEmailResetRequest extends CapiCommand {
  constructor(domain, identifier, user_id, client = "web") {
    super(["folksUserEmailReset", { domain, identifier, user_id, client }]);

    checkValue(domain, "domain", "string");
    checkValue(identifier, "identifier", "string", "undefined");
    checkValue(user_id, "user_id", "string", "undefined"); 
    checkValue(client, "client", "string");
  }
}

export class UserEmailResetCheck extends CapiCommand {
  constructor(token) {
    super(["folksUserEmailReset", { token, client: "web" }]);

    checkValue(token, "token", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkField(data, "domain", "string");
    checkField(data, "rule_idf", "string", "undefined");

    return { ...data };
  }
}

export class UserEmailResetFinish extends CapiCommand {
  constructor(token, password) {
    super(["folksUserEmailReset", { token, password, client: "web" }]);

    checkValue(token, "token", "string");
    checkValue(password, "password", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");
    checkField(data, "expiry", "string");

    return { ...data, expiry: parseDate(data.expiry) };
  }
}

export class UserChange extends CapiCommand {
  constructor(user_id, obj_args = {}) {
    super(["folksUserChange", [user_id], obj_args]);

    checkValue(user_id, "user_id", "string");
    checkValue(obj_args, "obj_args", "object");

    checkField(obj_args, "role", "string", "undefined");
    checkField(obj_args, "email", "string", "undefined");
    checkField(obj_args, "label", "string", "undefined");
    checkField(obj_args, "login", "string", "undefined");
    checkField(obj_args, "domain", "string", "undefined");
    checkField(obj_args, "dry", "boolean", "undefined");
    checkField(obj_args, "assume_kind", "string", "undefined");
  }
}

export class UserFlag extends CapiCommand {
  constructor(user_id, key, effect, reason) {
    super(["folksUserFlag", [user_id, key, effect], { reason }]);

    checkValue(user_id, "user_id", "string");
    checkValue(key, "key", "string");
    checkValue(effect, "effect", "string");
    checkValue(reason, "reason", "string", "undefined");
  }
}

function parseUserInfo(data) {
  checkField(data, "user_id", "string");
  checkField(data, "label", "string");
  checkField(data, "domain", "string");
  checkField(data, "created_at", "string", "undefined");

  checkField(data, "login", "string", "undefined");
  checkField(data, "role", "string", "undefined");
  checkField(data, "expiry", "string", "undefined");
  checkField(data, "login_at", "string", "undefined");

  checkField(data, "flags", "object", "undefined");

  checkField(data, "addresses", "array", "undefined");

  checkField(data, "props", "object", "undefined");

  let result = { ...data };
  if (data.created_at) result.created_at = parseDate(data.created_at);
  if (data.member_at) result.member_at = parseDate(data.member_at);
  if (data.expiry) result.expiry = parseDate(data.expiry);
  if (data.login_at) result.login_at = parseDate(data.login_at);

  return result;
}

export class UserInfo extends CapiCommand {
  userId;
  constructor({ user_id, flags, addresses, dereference, props, bonds, commits } = {}) {
    super(["folksUserInfo", { user_id, flags, addresses, dereference, props, bonds, commits }]);
    checkValue(user_id, "user_id", "string", "undefined");
    checkValue(flags, "flags", "boolean", "undefined");
    checkValue(addresses, "addresses", "boolean", "undefined");
    checkValue(dereference, "dereference", "boolean", "undefined");
    checkValue(props, "props", "boolean", "undefined");
    checkValue(bonds, "bonds", "boolean", "undefined");
    checkValue(commits, "commits", "boolean", "undefined");
    this.userId = user_id
  }

  parseData(status, data) {
    if (status !== 200) return data;

    return parseUserInfo(data);
  }

  getMarkerList() {
    return [`userInfo:${this.userId}`];
  }
}

export class UserToken extends CapiCommand {
  userId;
  constructor(  user_id, name, app, dev_id, token) {
    super(["folksUserToken", [ name, app, dev_id, token], {user_id, app, dev_id}]);
    checkValue(name, "user_id", "string", "undefined");
    checkValue(name, "name", "string", "undefined");
    checkValue(app, "app", "string", "undefined");
    checkValue(dev_id, "dev_id", "string", "undefined");
    checkValue(token, "token", "string", "undefined");

    this.userId = user_id
  }

  parseData(status, data) {
    if (status !== 200) return data;

    return parseUserInfo(data);
  }
}


// constructor(user_id, key, effect, reason) {
//   super(["folksUserFlag", [user_id, key, effect], { reason }]);
// ;
export class UserRelations extends CapiCommand {
  constructor(user_id) {
    super(["folksUserRelations", [user_id]]);
    checkValue(user_id, "user_id", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;
    return data;
  }
}

export class UserList extends CapiCommand {
  constructor({ domain, details } = {}) {
    super(["folksUserList", { domain, details }]);

    checkValue(domain, "domain", "string", "undefined");
    checkValue(details, "details", "boolean", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "array");

    return data.map(user => parseUserInfo(user));
  }
}

export class UserList2 extends CapiCommand {
  constructor({
    roles,
    kinds,
    property,
    query,
    order,
    after,
    before,
    volume,
    members_of,
    props,
    levels,
  } = {}) {
    super([
      "folksUserList2",
      { roles, kinds, property, query, order, after, before, volume, members_of, props, levels }
    ]);

    checkValue(roles, "roles", "array", "undefined");
    checkValue(kinds, "kinds", "array", "undefined");
    checkValue(props, "kinds", "array", "undefined");
    checkValue(levels, "kinds", "array", "undefined");
    checkValue(property, "property", "string", "undefined");
    checkValue(query, "query", "string", "undefined");
    checkValue(order, "order", "string", "undefined");
    checkValue(members_of, "members_of", "string", "undefined");

    checkValue(after, "after", "array", "undefined", "null");
    checkValue(before, "before", "array", "undefined", "null");
    checkValue(volume, "volume", "number", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "p8n", "string");
    checkField(data, "prev", "array", "null");
    checkField(data, "next", "array", "null");
    checkField(data, "more", "array", "undefined");
    checkField(data, "volume", "number");
    checkField(data, "total", "number", "undefined");
    checkField(data, "results", "array", "undefined");
    
    data.results = data.results.map(parseUserInfo);
    
    return data;
  }
}

export class ParamsDef extends CommonParamsDef.bind("folksParamsDef") {}
export class ParamsGet extends CommonParamsGet.bind("folksParamsGet") {}
export class ParamsSet extends CommonParamsSet.bind("folksParamsSet") {}

export class StorageGet extends CapiCommand {
  constructor(key) {
    super(["folksStorageGet", [key]]);

    checkValue(key, "key", "string");
    
    this.key = key;
  }

  getMarkerList() {
    return [`storage:${this.key}`];
  }
}

export class StoragePut extends CapiCommand {
  constructor(key, value) {
    super(["folksStoragePut", [key, value]]);

    checkValue(key, "key", "string");
    checkValue(value, "value", "string");
  }
}

export class UserRef extends CapiCommand {
  constructor(kind, key, value) {
    super(["folksUserRef", [kind, key, value]]);

    checkValue(kind, "kind", "string");
    checkValue(key, "key", "string");
    checkValue(value, "value", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "user_id", "string");
    checkField(data, "label", "string");
    checkField(data, "role", "string");
    checkField(data, "kind", "string");
    checkField(data, "domain", "string");

    return { ...data };
  }
}

export class UserRefDry extends CapiCommand {
  constructor(kind, key, value) {
    super(["folksUserRef", [kind, key, value], { dry: true }]);
    
    checkValue(kind, "kind", "string");
    checkValue(key, "key", "string");
    checkValue(value, "value", "string");
  }
  
  parseData(status, data) {
    if (status !== 200) return data;
    
    checkValue(data, "data", "object");
    
    checkField(data, "user_id", "string");
    checkField(data, "label", "string");
    checkField(data, "role", "string");
    checkField(data, "kind", "string");
    checkField(data, "domain", "string");
    
    return { ...data };
  }
}

export class GroupCreate extends CapiCommand {
  constructor(kind, label) {
    super(["folksGroupCreate", [kind, label]]);

    checkValue(kind, "kind", "string");
    checkValue(label, "label", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "user_id", "string");

    return { ...data };
  }
}

export class UserSignup extends CapiCommand {
  constructor(domain, email, password) {
    super(["folksUserSignup", [domain, email], { password, client: "web" }]);

    checkValue(domain, "domain", "string");
    checkValue(email, "email", "string");
    checkValue(password, "password", "string");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "user_id", "string");

    return { ...data };
  }
}

export class MemberList extends CapiCommand {
  constructor(group_id, at, volume) {
    super(["folksMemberList", [group_id], { at, volume }]);

    checkValue(group_id, "group_id", "string");
    checkValue(at, "at", "number", "undefined");
    checkValue(volume, "volume", "number", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "at", "number");
    checkField(data, "next", "number", "null");
    checkField(data, "p8n", "string");
    checkField(data, "prev", "number", "null");
    checkField(data, "results", "array");
    checkField(data, "total", "number");
    checkField(data, "volume", "number");

    return { ...data };
  }
}

export class AddressCreate extends CapiCommand {
  constructor(kind, address, user_id, confiscate_from, dry=false) {
    super([
      "folksAddressCreate",
      [kind, address],
      { user_id, confiscate_from, client: "web", dry }
    ]);

    checkValue(kind, "kind", "string");
    checkValue(address, "address", "string");
    checkValue(user_id, "user_id", "string", "undefined");
    checkValue(confiscate_from, "confiscate_from", "string", "undefined");
    checkValue(dry, "dry", "boolean", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "sent_via", "string", "undefined");
    checkField(data, "not_sent", "string", "undefined");

    return { ...data };
  }
}

export class AddressDelete extends CapiCommand {
  constructor(kind, address, user_id) {
    super(["folksAddressDelete", [kind, address], { user_id }]);

    checkValue(kind, "kind", "string");
    checkValue(address, "address", "string");
    checkValue(user_id, "user_id", "string");
  }
}

export class AddressCancel extends CapiCommand {
  constructor(token) {
    super(["folksAddressCancel", [token]]);

    checkValue(token, "token", "string");
  }
}

export class AddressVerify extends CapiCommand {
  constructor(token, confiscate_from, address) {
    super(["folksAddressVerify", [token], { confiscate_from, address }]);

    checkValue(token, "token", "string");
    checkValue(confiscate_from, "confiscate_from", "string", "undefined");
    checkValue(address, "address", "string", "undefined");
  }
}

export class AddressResend extends CapiCommand {
  constructor(kind, address, user_id) {
    super(["folksAddressResend", [kind, address], { user_id, client: "web" }]);

    checkValue(kind, "kind", "string");
    checkValue(address, "address", "string");
    checkValue(user_id, "user_id", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "sent_via", "string", "undefined");
    checkField(data, "not_sent", "string", "undefined");

    return { ...data };
  }
}

export class EnumDictionary extends CapiCommand {
  constructor(dictionaries = "all") {
    super(["enumDictionary", [dictionaries]]);

    checkValue(dictionaries, "dictionaries", "string", "array", "undefined");
  }
}

export class UserForm extends CapiCommand {
  constructor(role, user_id) {
    super(["folksUserForm", [], { user_id, role }]);

    checkValue(user_id, "user_id", "string", "undefined");
    checkValue(role, "role", "string", "undefined");
  }
}

export class MemberUpdate extends CapiCommand {
  constructor(group_id, member_id, level) {
    super(["folksMemberUpdate", [group_id, member_id, level]]);

    checkValue(group_id, "group_id", "string");
    checkValue(member_id, "member_id", "string");
    checkValue(level, "level", "string");
  }
}

export class ReportCount extends CapiCommand {
  constructor(report_name, date_from, date_to) {
    super(["folksReportCount", [report_name, date_from, date_to]]);

    checkValue(report_name, "report_name", "string");
    checkValue(date_from, "date_from", "string", "undefined");
    checkValue(date_to, "date_to", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");

    // TODO: Sprawdzić wedle nazwy raportu.
    /*checkField(data, "anonymous_count", "number", "undefined");
    checkField(data, "authorized_count", "number", "undefined");
    checkField(data, "anonymous_time", "number", "undefined");
    checkField(data, "authorized_time", "number", "undefined");

    checkField(data, "unique_users_count", "number", "undefined");*/

    return { ...data };
  }
}
